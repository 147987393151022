import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/core/services/auth.service';
import { CommonService } from '@app/core/services/common.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { DropDownSortingService } from '@app/core/services/drop-down-sorting.service';
import { FacebookLoginEbatesComponent } from "../facebook-login-ebates/facebook-login-ebates.component";
import { CartService } from '@app/shared/service/cart.service';
import { Router } from '@angular/router';
import { UtilityService } from '@app/core/services/utility.service';
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { AlertModalComponent } from '@app/alert/alert-modal/alert-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  hide = true;
  passHide = true;
  login = true;
  forget = false;
  register = false;
  guestCheckout: boolean = false;
  isstaylogin = false;
  process: boolean = false;
  error = false;
  success = false;
  message: string = '';
  loginForm: UntypedFormGroup;
  forgotpasswordform: UntypedFormGroup;
  registerform: UntypedFormGroup;
  guestCheckoutForm: UntypedFormGroup;
  loginusername = new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[^@]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
  loginpassword = new UntypedFormControl('', [
    Validators.required
  ]);
  guestuseremail = new UntypedFormControl('', [
    Validators.required, Validators.email, Validators.pattern('^[^@]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')
  ]);
  password = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(
      /(?=.*[A-Za-z])(?=.*\d)(?=.*[\\/\~`!@#$%^&*()_+={[}\]|\:;"'<,>.?-])[A-Za-z\d~`\\/\!@#$%^&*()_+={[}\]|\:;"'<,>.?-]{8,}$/
    ),
  ]);
  username = new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern('^[^@]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]);
  forgotusername = new UntypedFormControl('', [Validators.required]);
  postalcode = new UntypedFormControl('', [Validators.required]);
  phone = new UntypedFormControl('', [Validators.required,Validators.pattern(/^\d{10}$/)]);
  countrycode = new UntypedFormControl(null);
  firstname = new UntypedFormControl('');
  lastname = new UntypedFormControl('');
  city = new UntypedFormControl('');
  state = new UntypedFormControl(null, [Validators.required]);
  streetAddress = new UntypedFormControl('');
  confirmpassword = new UntypedFormControl('', [Validators.required]);
  opted_nightly_email = new UntypedFormControl(false);
  // validator= this.confirmedValidator(this.password, this.confirmpassword)
  // , [  Validators.required,  Validators.pattern( /(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/ ),]


  selectedCountry: any[] = [];
  private loginsubscription: Subscription;
  private registerscription: Subscription;
  private forgotpassword2pscription: Subscription;
  private commonServiceSubscription: Subscription;
  private stateFocusSubscription: Subscription;
  public is_google: boolean = false;
  public is_facebook: boolean = false;
  selectedState: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<LoginComponent>,
    private fBuilder: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private auth: AuthService,
    private commonService: CommonService,
    readonly dataApiService: DataApiService,
    public dropDownSort: DropDownSortingService,
    readonly cartService: CartService,
    public router: Router,
    private utilityService: UtilityService,
    private ga: GoogleAnalyticsService,
  ) {
    this.initilizeFormBuilder();
    this.checkGuestDataPresent();
  }

  ngOnInit(): void {
    if (this.data) {
      this.login = false;
      this.register = true;
      this.patchRegisterFormData();
    }

    if (localStorage.getItem('rememberAuth')) {
      let rememberObj: any = JSON.parse(localStorage.getItem('rememberAuth'))
      this.loginForm.patchValue({
        loginusername: rememberObj.Email,
        loginpassword: this.commonService.getDecode(rememberObj.Password)
      })
      this.isstaylogin = true;
    }
    // this.auth.authToken.subscribe((val:any)=> {
    //   console.log(val);
    //   if(val.hasOwnProperty('auth') && val.auth !== ''){
    //     this.dataApiService.getCustomerStockCheck().subscribe((val:any)=> {
    //       console.log(val);

    //     })
    //   }

    // })
    this.setStateDropdown();
  }
  setStateDropdown(): void {
    const country_id = 233; // country_id 233 is for USA.
    if (country_id)
      this.stateFocusSubscription = this.commonService.getStateByCountry(country_id).subscribe((response: any) => {
        if (response.length > 0) {
          this.selectedState = response;
          this.selectedState = this.dropDownSort.sort(this.selectedState, 'name');
        }
      });
  };
  oncalldropdownvalue(name: string) {
    // if (name === 'country' && this.selectedCountry.length === 0) {
    //   this.setCountryDropdown();
    // }
    if (name === 'state') {
      this.setStateDropdown();
    }
  }
  preventWhitespace(event: any) {
    var key = event.keyCode;
    if (key === 32) {
      event.preventDefault();
    }
  }

  closePopup() {
    if (this.router.url.includes('login') || this.router.url.includes('register')) {
      this.router.navigate(['']);
    }
    this.dialogRef.close();
  }
  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  /**
   * Method to initialize forms. .
   */
  initilizeFormBuilder(): void {
    this.loginForm = this.fBuilder.group({
      loginusername: this.loginusername,
      loginpassword: this.loginpassword,
    });
    this.registerform = this.fBuilder.group({
      username: this.username,
      phone: this.phone,
      countrycode: this.countrycode,
      password: this.password,
      confirmpassword: this.confirmpassword,
      firstname: this.firstname,
      middlename: '',
      conversionevent: '',
      channel: '',
      lastname: this.lastname,
      status: 'Active',
      postalcode: this.postalcode,
      gendertypecode: '',
      country: 233,
      address1: this.streetAddress,
      address2: '',
      city: this.city,
      state: this.state,
      employeeimage: '',
      conversionstate: '',
      dateofbirth: null,
      opted_nightly_email: this.opted_nightly_email
      // validator :  this.confirmedValidator(this.password, this.confirmpassword)
    }, {
      validator: this.confirmedValidator('password', 'confirmpassword')
    });
    this.forgotpasswordform = this.fBuilder.group({
      forgotusername: this.forgotusername,
    });
    this.guestCheckoutForm = this.fBuilder.group({
      guestuseremail: this.guestuseremail,
    });
  }
  /**
   * Method to change the value of remember me checkbox .
   */
  onSelectStayLogin(event: any): void {
    this.isstaylogin = !this.isstaylogin;
  }
  /**
   * Method to get the value of register forms .
   * @returns object containg key value of the register form.
   */
  get registerbodyData() {
    return this.registerform.value;
  }
  /**
   * Method to get the value of login forms  .
   * @returns object containg key value of the login form.
   */
  get loginbodyData() {
    return this.loginForm.value;
  }
  /**
   * Method to get the value of forgotpassword forms  .
   * @returns object containg key value of the forgot password form.
   */
  get forgotpasswordbodyData() {
    return this.forgotpasswordform.value;
  }
  /**
   * Method to start api call for register forn.
   * @returns response for  api.
   */
  getPhoneValue(event: any) {
    if (event !== undefined && event !== '') {
      let newVal: any;
      newVal = event.target.value;
      if (newVal.length < 1) {
        // newVal = `+1 ${newVal}`;
      }
      this.registerform.patchValue({
        phone: newVal
      });
    }
  }
  public isPasswordSame: boolean = false;
  confirmedValidator(controlName: string, matchingControlName: any) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl) {
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
          this.isPasswordSame = (matchingControl.status == 'VALID') ? true : false;
        } else {
          matchingControl.setErrors(null);
          this.isPasswordSame = (matchingControl.status == 'VALID') ? true : false;
        }
      }
    }
  }
  onRegister() {
    this.registerform.markAllAsTouched();
    if (this.registerform.invalid) {
      return;
    } else if (this.registerbodyData.password !== this.confirmpassword.value) {
      this.error = true;
      this.message = 'Your password and confirm password are not same.';
      return;
    } else {
      this.process = true;
      this.commonService.setGlobalRouterLaoder(true);
      this.loginsubscription = this.auth
        .postRegister(this.registerbodyData)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            this.auth.setUserCredentials(res, this.isstaylogin);
            this.localStoreCartDetails('registration');
            this.commonService.getCartItem();
            this.error = false;
            //this.dialogRef.close();
            this.process = false;
            this.commonService.setGlobalRouterLaoder(false);
            this.ga.registraionSuccess(res);
            if (!environment.isDevServer) this.router.navigate(['/welcome-to-the-buyers-club']);
          },
          (error: any) => {
            this.error = true;
            if (error.hasOwnProperty('error') && error['error'].hasOwnProperty('message')) this.message = error['error']['message'];
            else this.message = 'User already exists.';
            this.process = false;
            this.commonService.setGlobalRouterLaoder(false);
          }
        );
    }
  }
  /**
   * Method to start api call for forgotpassword forn.
   *  @returns response for  api.
   */
  onForgotpassword() {
    if (this.forgotpasswordform.invalid) {
      return;
    } else {
      this.process = true;
      const forgot_obj = {
        username: this.forgotpasswordbodyData.forgotusername.trim().toLowerCase(),
        'redirecturl': `${document.location.hostname}/user/resetpassword`
      };
      this.loginsubscription = this.auth
        .postForgotpassword(forgot_obj)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            this.error = false;
            this.forget = !this.forget;
            this.login = !this.login;
            this.process = false;
            this.commonService.openSnackbar(`An email was sent to you.`, 30000, 'X');
          },
          (error: any) => {
            if (error.hasOwnProperty('error') && error['error'].hasOwnProperty('msg')) this.message = error['error']['msg'];
            if (error.hasOwnProperty('error') && error['error'].hasOwnProperty('is_google')) this.is_google = error['error']['is_google'];
            if (error.hasOwnProperty('error') && error['error'].hasOwnProperty('is_facebook')) this.is_facebook = error['error']['is_facebook'];
            else this.message = 'User does not exists.';
            this.error = true;
            this.process = false;
          }
        );
    }
  }
  /**
   * Method to start api call for login forn.
   *  @returns response for  api.
   */
  onLogin() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    } else {
      this.process = true;
      const login_obj = {
        username: this.loginbodyData.loginusername,
        password: this.loginbodyData.loginpassword,
        "access_token": "",
        "provider": "normal",
      };
      this.loginsubscription = this.auth
        .postLogin(login_obj)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            let passEncode: string = this.commonService.getEncode(this.loginbodyData.loginpassword)
            this.error = false;
            // this.dialogRef.close(res);
            this.process = false;

            // ++ Add local Store Cart in your profifle
            this.auth.setUserCredentials(res, this.isstaylogin, passEncode);
            this.localStoreCartDetails(res);
            res.customerorder_id = localStorage.getItem('customerorder_id')
            this.auth.setUserCredentials(res, this.isstaylogin, passEncode);
            this.commonService.getCartItem();
            this.dataApiService.getCustomerStockCheck().subscribe((val: any) => {
              if (val.hasOwnProperty('api_status') && val.api_status == 1) {
                localStorage.setItem('is_stock', val.is_stock);
                this.cartService.setIsInStock(val.is_stock);
              }
            })
          },
          (error: any) => {
            this.error = true;
            if (error?.error?.errors) this.message = error.error.errors;
            else this.message = 'Invalid login credentials';
            this.process = false;
          }
        );
    }
  }
  /**
     * Method to start api call for social login with facebook and google.
     * @param res response after social sign in;
     * @param provider String of provider name;
     *  @returns response for  api.
     */
  onSocialLogin(res: any, provider: string, email?: string) {
    if (res && res.credential && res.credential.accessToken) {
      const login_obj = {
        username: email || "",
        password: "",
        "access_token": res.credential.accessToken,
        "provider": provider,
      };
      this.loginsubscription = this.auth
        .postLogin(login_obj)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            this.auth.setUserCredentials(res, this.isstaylogin);
            this.error = false;
            //this.dialogRef.close("success");

            // ++ We need to modify
            this.localStoreCartDetails("success");
            this.commonService.getCartItem();
          },
          (error: any) => {
            if (error.status === 400 && error.error.ebatePopupRequired) {
              const dialog = this.matDialog.open(FacebookLoginEbatesComponent, {
                disableClose: true
              });
              dialog.afterClosed().subscribe((result) => {
                if (result) {
                  this.onSocialLogin(res, provider, result);
                } else {
                  this.dialogRef.close();
                }
              })
            } else {
              this.error = true;
              this.message = 'Invalid login credentials';
            }
          }
        );
    }
  }
  /**
     * Method to start Facebook sign in.
     *  @returns response for valid user.
     */
  onFacebookLogin() {
    this.auth.onFacebookAuth().then((result: any) => {
      this.onSocialLogin(result, "facebook");
    }).catch((error: any) => {
      // console.log(error)

    });
  }
  /**
   * Method to start google sign in.
   *  @returns response for valid user.
   */
  onGoogleLogin() {
    this.auth.onGoogleAuth().then((result: any) => {
      this.onSocialLogin(result, 'google');
    }).catch((error: any) => {
      // console.log(error)
    });
  }

  setCountryDropdown(): void {
    const dropdownTables = [
      'country',
    ];
    this.commonServiceSubscription = this.commonService.getCommonDropDowndata(dropdownTables).subscribe((data: any) => {
      if (data.length > 0) {
        this.selectedCountry =
          data[0] && data[0].length > 0 ? (data[0][0] ? data[0][0].data : []) : [];


        this.selectedCountry = this.dropDownSort.sort(this.selectedCountry, 'name');
      }
    });
  };

  localStoreCartDetails(content?: any) {
    const cartDetailsStorage = JSON.parse(localStorage.getItem('cartDetailsStorage'));
    const storeId = localStorage.getItem('storeId');
    const businessUnitId = localStorage.getItem('businessUnitId');
    const customerId = this.auth.customer_id;
  
    if (!cartDetailsStorage || cartDetailsStorage.length === 0) {
      this.dialogRef.close(content);
      return;
    }
  
    this.processExistingCart(cartDetailsStorage, storeId, businessUnitId, customerId, content);
  }
  
  private processExistingCart(cartDetailsStorage: any[], storeId: string, businessUnitId: string, customerId: string, content: any) {
    this.commonService.getCartDetails().subscribe((existCartData: any) => {
      this.handleExistingCartData(existCartData, cartDetailsStorage, storeId, businessUnitId, customerId, content);
    }, error => {
      this.dialogRef.close(content);
      console.error(error);
    });
  }
  
  private handleExistingCartData(existCartData: any, cartDetailsStorage: any[], storeId: string, businessUnitId: string, customerId: string, content: any) {
    localStorage.setItem('booking_flag', existCartData?.booking_flag);
    localStorage.setItem('booking_store', existCartData?.store);
  
    if (existCartData && existCartData.data.length > 0) {
      this.cartService.setCartDuringLogin(existCartData);
  
      existCartData.data.forEach((e: any) => {
        this.updateCartDetailsQuantity(cartDetailsStorage, e);
      });
    }
  
    this.addToCartAndHandleResponse(cartDetailsStorage, businessUnitId, storeId, customerId, content);
  }
  
  private updateCartDetailsQuantity(cartDetailsStorage: any[], cartData: any) {
    const cartExistData = cartDetailsStorage.filter((x: any) => x.item_id === +cartData.item_id);
  
    if (cartExistData.length > 0) {
      cartExistData[0].quantity += cartData.quantity;
    }
  }
  
  private addToCartAndHandleResponse(cartDetailsStorage: any[], businessUnitId: string, storeId: string, customerId: string, content: any) {
    const obj2: any = {
      businessunitid: businessUnitId,
      storeid: storeId,
      customerid: customerId,
      status: "Active",
      lineitems: cartDetailsStorage
    };
  
    const { booking_id, event_id, ordertype, storeid } = cartDetailsStorage[0];
    const newObj = { 
      ...(obj2 || {}), 
      ...(booking_id !== undefined ? { booking_id } : {}), 
      ...(event_id !== undefined ? { event_id } : {}),
      ...(ordertype !== undefined ? { ordertype } : {}), 
      ...(storeid !== undefined ? { storeid } : {})
    };

  
    this.commonService.addToCart(newObj, +customerId).subscribe((value: any) => {
      this.handleAddToCartSuccess(value, content);
    }, error => {
      this.handleAddToCartError(error, newObj, content);
    });
  }
  
  private handleAddToCartSuccess(value: any, content: any) {
    if (value) {
      localStorage.removeItem('cartDetailsStorage');
      this.commonService.getCartItem();
  
      const customerorderId = value.data?.cart_body?.customerorder_id;
      localStorage.setItem('customerorder_id', customerorderId);
  
      this.dialogRef.close(content);
  
      if (content === 'registration') {
        this.router.navigate(['/checkout/order-review']);
      }
    }
  }
  
  private handleAddToCartError(error: any, newObj: any, content: any) {
    console.error(error);
    this.dialogRef.close(content);
  
    const isBookingError = error.error?.cart_info?.cart_category === 'booking';
  
    localStorage.setItem('booking_flag', isBookingError ? 'true' : 'false');
    localStorage.setItem('booking_store', isBookingError ? error.error?.cart_info?.event_store : '');
  
    setTimeout(() => {
      this.handleErrorWithModal(error, newObj);
    }, 200);
  }
  
  private handleErrorWithModal(err: any, cartdata: any) {
    if (err?.error.message) {
      // Handle error message
      const dialogRef = this.matDialog.open(AlertModalComponent, {
        panelClass: ['pop-up', 'sm-pop'],
        data: {
          displayTitle: 'Reset cart!', displayMsg: err?.error.message, closeBtn: true, confirmButtonText: 'Complete Purchase', cancelButtonText: 'Reset cart'
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result === "success") {
          // Complete purchase functionality
          this.router.navigate(['/cart']);
        } else if (result == 'Reset cart') {
          // Reset cart functionality'
          this.handleResetCart(cartdata, cartdata);
        }
      });
    }
  }
  private handleResetCart(item: any, booking_data: any) {
    let cartDataInfo: any = [];
    cartDataInfo = this.cartService.getCartItemArray();
    if (cartDataInfo.length === 0) {
      this.commonService.getCartItem();
    }
    cartDataInfo = this.cartService.getCartItemArray();
    const deletePromises = cartDataInfo.map(item => this.commonService.deleteCartNew(item.customerorderproductlineitem_id, null));

    Promise.all(deletePromises)
      .then((values) => {
        this.commonService.addToCart(item, +item?.customerid).subscribe(({booking_flag}) => {
          if (!booking_flag) {
            localStorage.setItem('booking_flag', 'false');
            localStorage.removeItem('booking_store')
          } else {
            localStorage.setItem('booking_flag', 'true');
            localStorage.setItem('booking_store', item?.storeid);
          }
          setTimeout(() => {
            if(this.router.url.includes('/cart') || this.router.url.includes('/checkout')){
              this.commonService.getCartItem();
              this.dataApiService.reloadCurrentRoute('/cart');
            }
          }, 300);
        }, error => this.handleAddcartError(error))
      })
      .catch((error) => {
        console.error('Error resetting cart:', error);
        this.commonService.getCartItem()
      });
  }
  private handleAddcartError(error:any) {
    if (error?.error.clear_cart) {
      this.commonService.getCartItem();
      this.commonService.openSnackbar(error?.error.message);
      this.router.navigate(['/booking']);
      return;
    }
  }

  getHeaderImages(imageName: string, moduleName: string, status: boolean, width?: number, height?: number) {
    height = height || 100;
    width = width || 100;
    return this.utilityService.getLazyImage(imageName, moduleName, status, `${width}x${height}`);
  }

  termsAndPrivacyUrl() {
    return environment.isDevServer ? 'privacy-policy' : 'terms-of-use-privacy-policy';
  }
  checkoutAsGuest(){
    const cartDetailsStorageArr = localStorage.getItem('cartDetailsStorage');
    const parsedCartDetails = cartDetailsStorageArr ? JSON.parse(cartDetailsStorageArr) : [];
    return parsedCartDetails.length > 0;
  }
  async onGuestCheckoutSubmit() {
    localStorage.setItem('guestUserData', JSON.stringify(this.guestCheckoutForm.value));
    const url = this.router.url.includes('/booking/listing/');
    if (url) {
      this.router.navigate(['/cart']);
      this.dialogRef.close();
      return;
    };
    this.router.navigate(['/guest-user/order-overview']);
    this.dialogRef.close();
  }
  checkGuestDataPresent(){
    const guestData = JSON.parse(localStorage.getItem('guestUserData') || null);
    if(guestData){
      this.guestCheckoutForm.patchValue({ guestuseremail: guestData?.guestuseremail})
    }
  }
  patchRegisterFormData(){
    const guestUserData = JSON.parse(sessionStorage.getItem('guestData') || null);
    if(!guestUserData) return;
    this.registerform.patchValue({
      firstname: guestUserData.firstname,
      lastname: guestUserData.lastname,
      username: guestUserData.email,
      phone: guestUserData.phone,
      address1: guestUserData.address1,
      address2: guestUserData.address2,
      city: guestUserData.city,
      state: guestUserData.state,
      postalcode: guestUserData.postalcode,
    });
  }

  ngOnDestroy() {
    if (this.loginsubscription) {
      this.loginsubscription.unsubscribe();
    }
    if (this.registerscription) {
      this.registerscription.unsubscribe();
    }
    if (this.forgotpassword2pscription) {
      this.forgotpassword2pscription.unsubscribe();
    }
    if (this.commonServiceSubscription) {
      this.commonServiceSubscription.unsubscribe();
    }
    if (this.stateFocusSubscription) {
      this.stateFocusSubscription.unsubscribe();
    }
  }
}
