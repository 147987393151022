import {
  Component,
  OnInit,
  HostListener,
  DoCheck,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  ViewChild,
  Renderer2,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { UtilityService } from '@app/core/services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginComponent } from '../../login/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/core/services/auth.service';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { CartService } from '@app/shared/service/cart.service';
import { environment } from 'environments/environment';
import { AlertModalComponent } from '@app/alert/alert-modal/alert-modal.component';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, DoCheck, OnDestroy {
  LocationTimingBox = false;
  black = 'rgba(0, 0, 0, 0.12)';
  isSticky: boolean = false;
  pSp = window.pageYOffset;
  cSp = window.pageYOffset;
  LocationBox = false;
  mobileNav = false;
  tabNav = false;
  allStore: Array<any>;
  search: string = '';
  curentStoreName: string = '';
  currentStoreOpenTill: string = '';
  curentStoreId: any = '';
  currentLat: any = '';
  currentLon: any = '';
  isloggedin: boolean = false;
  username: string = '';
  cartCount: number = 0;
  imageWidth: any;
  imageHeight: any;
  environment: any = environment;
  isMapClicked = false;
  @Output() getNavigation = new EventEmitter();
  @Output() changeMegaMenu = new EventEmitter();
  private usersubscription: Subscription;
  navHeaderMenu: any = [];
  navMenuItem: any = [];
  functionId: any = 0;
  cartData: any;
  firstTimeCalled: boolean = false;
  locationChecked: boolean = false;
  storeNumber: any;
  qParamStoreNo: any;
  locationSetFromURL: boolean = false;
  private cartSubscription: Subscription;
  private isActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public commonService: CommonService,
    private utilityService: UtilityService,
    public router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private cdRef: ChangeDetectorRef,
    readonly dataApiService: DataApiService,
    private activatedRoute: ActivatedRoute,
    readonly location: Location,
    public cartService: CartService,
    private renderer: Renderer2,
    private ga: GoogleAnalyticsService
  ) {
    this.imageWidth = 0;
    this.imageHeight = 0;

  }


  ngOnInit(): void {

    this.resize();
    this.storeNumber = localStorage.getItem('storenumber');
    if (!this.commonService.isUserAgentForSpeedTest()) {
      this.commonService.getCartItem();
      this.commonService.storeChangeOnCheckout.subscribe((value: any) => {
        if (value) {
          this.storeBox();
        }
      }, (error) => console.error(error));
    }

    this.allStore = [];
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    const queryParams = new URLSearchParams(window.location.search);
    this.qParamStoreNo = queryParams.get('store_no');

    if (!this.router.url.includes('user/resetpassword')) {
      this.getStore();
      this.getLoginsubscriptiondata();
      this.getLocalstorageValue();
      !this.locationSetFromURL && this.getLocationDetails();
    }



    /**Wish List Call */
    this.auth.authToken.subscribe((value) => {
      if (value.isLoggedin === 'true') {
        this.commonService.getWishList();
        this.cartService.cartDuringLogin.subscribe((value) => {
          if (!this.firstTimeCalled && !this.router.url.includes('/checkout') && value !== null && value?.count > 0 && value?.data.length > 0) {
            this.cartData = value;
            this.storeChecker();
            this.firstTimeCalled = true;
          }
        }, (error) => console.error(error));
      }
      else {
        /**To remove Duplicate item if it is already store in local storage */
        let previousRecentViewedItemsArray: any = [];
        const previousRecentViewedItems = localStorage.getItem('recentViewedItems');
        if (previousRecentViewedItems) {
          previousRecentViewedItemsArray = previousRecentViewedItems.split(",");
        }
        previousRecentViewedItemsArray = previousRecentViewedItemsArray?.filter((item: any, pos: any) => previousRecentViewedItemsArray.indexOf(item) === pos)
        let recentViewedItems: any = [];
        recentViewedItems = previousRecentViewedItemsArray.join(",");
        if (recentViewedItems) {
          localStorage.setItem('recentViewedItems', recentViewedItems);
        }
      }
    });

    if (isPlatformBrowser(this.platformId)) this.getTopHeaderItems();
    this.getGlobalScript();

    this.cartService.closingStoreBoxObs.subscribe((value) => {
      if (value) {
        const element2 = document.getElementById('Cart-Box');
        element2?.classList?.remove('active');
      }
    }, (error) => console.error(error));
    
    this.cartSubscription = this.cartService.iscartSetObs.subscribe((data: any) => {
      if (data.length) {
        this.isActiveSubject.next(true);
      } else {
        this.isActiveSubject.next(false);
      }
      this.cdRef.detectChanges()
    });
  }
  mapClicked() {
    if (Object.keys(this.currentStoreDetails).length > 0) {
      let address: any = this.getFullAddress(this.currentStoreDetails);
      this.ga.locationFlyoutClicked(this.currentStoreDetails, address, this.allStore.length);
      const mapUrl = 'https://maps.google.com/maps?q=' + address + '&z=10&ie=UTF8&iwloc=&output=embed';
      window.open(mapUrl, '_blank');
      // this.alreadyCalledMap = true;
    }
  }
  getLocationDetails() {
    if (isPlatformBrowser(this.platformId) && !this.geoLocationCalled) {
      this.commonService.getPosition().then((pos) => {
        if (pos) {
          navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
              this.currentLat = pos.lat;
              this.currentLon = pos.lng;
              this.calculateDistanceOfStore();
              if (this.allStore.length > 0) {
                this.allStore.sort((a: any, b: any) => a.nearestDistance - b.nearestDistance);
                this.shopThisStore(this.allStore[0]);
              }
              this.geoLocationCalled = true;
            }
          });
        }
      });

    }
  }

  storeChecker() {
    let savedStoreId = localStorage.getItem('storeId');
    const savedStoreArray = this.allStore?.filter((a: any) => a.id == this.cartData?.store);
    if (this.cartData && this.cartData.store == savedStoreId) {
    } else {
      if (savedStoreArray.length > 0) {
        this.shopThisStore(savedStoreArray);
      }
    }
  }

  getGlobalScript() {
    if (JSON.parse(localStorage.getItem('businessUnitId'))) {
      this.commonService.getGlobalScript().subscribe((value: any) => {
        if (value) {
          this.commonService.appendGlobalScript(value.script)
        }
      }, (error) => console.error(error));
    } else {
      setTimeout(() => {
        this.getGlobalScript()
      }, 50);
    }
  }
  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  get getCount() {
    return this.cartService.getCount();
  }

  get getCartCount() {
    return this.cartService.getCount() ? `${this.cartService.getCount() > 99 ? '99+' : this.cartService.getCount()}` : "";
  }

  getLoginsubscriptiondata() {
    this.usersubscription = this.auth.authToken.subscribe((value) => {
      if (value) {
        this.isloggedin = value.isLoggedin === 'true' ? true : false;
        this.username = value.userfirstname;
      }
    });
  }

  pageRoot: string = '';
  geoLocationCalled: boolean = false;
  ngDoCheck() {

    this.getLocalstorageValue();
    this.allStore.sort((a: any, b: any) => a.nearestDistance - b.nearestDistance);
    const cartDetailsStorageExist = localStorage.getItem('cartDetailsStorage');
    if (!this.isLoggedin && cartDetailsStorageExist == null) localStorage.setItem('cartDetailsStorage', '[]');
    this.auth.storeWiseProductStock.subscribe((data: any) => {
      if (this.allStore.length > 0) {
        this.allStore.forEach((element: any) => {
          element.stock = 0;
          data.forEach((item: any) => {
            if (element.id === item.store) {
              element.stock = Math.floor(item.actual_stock);
            }
          });
        });
      }
    }, (error) => console.error(error));

    const url = this.location.path();
    if (this.router.url.includes('/checkout/order-review')) {
      this.pageRoot = 'review';
    }
    if (this.router.url.includes('/checkout/checkout-billing')) {
      this.pageRoot = 'billing';
    }
    if (this.router.url.includes('/checkout/order-complete')) {
      this.pageRoot = 'order';
    }
    if (this.router.url.includes('/giftcards/giftcard-billing')) {
      this.pageRoot = 'giftcard-billing';
    }
    if (this.router.url.includes('/giftcards/giftcard-shipping')) {
      this.pageRoot = 'giftcard-shipping';
    }
    if (this.router.url.includes('/giftcards/order-complete')) {
      this.pageRoot = 'giftcard';
    }
    if (this.router.url.includes('/guest-user/checkout-billing')) {
      this.pageRoot = 'guestuser-billing';
    } 
    if (this.router.url.includes('/guest-user/order-complete')) {
      this.pageRoot = 'guestuser-ordercomplete';
    }
    if (this.router.url.includes('/guest-user/order-overview')) {
      this.pageRoot = 'guestuser-overview';
    }

    if (isPlatformBrowser(this.platformId) && !this.geoLocationCalled) {
      if (document.getElementById("Location-Box")?.className.includes("active") || document.getElementById("Store-Box")?.className.includes("active")) {
        this.commonService.getPosition().then((pos) => {
          this.currentLat = pos.lat;
          this.currentLon = pos.lng;
          this.calculateDistanceOfStore();
          this.geoLocationCalled = true;
        });
      }
    }
  }

  locationWiseStoreCheck() {
    if (this.qParamStoreNo && this.allStore.length) {
      console.log(this.qParamStoreNo);
      const storeOnParam = this.allStore?.filter((item: any) => item.storenumber == this.qParamStoreNo);
      if (storeOnParam?.length) {
        this.shopThisStore(storeOnParam[0], true);
        this.router.navigate([], {
          queryParams: { store_no: null },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });
        this.locationSetFromURL = true;
      } else this.checkSessionStore(true);
    } else this.checkSessionStore();
  }
  checkSessionStore(storeOnURL?: boolean) {
    const sessionStoreId = isPlatformBrowser(this.platformId) ? localStorage.getItem('storeId') : '';
    if (!sessionStoreId) {
      let nearestStoreId = '';
      let defaultStoreExists: boolean = false;
      if (this.allStore.length > 0) {
        // this.allStore.sort((a: any, b: any) => a.nearestDistance - b.nearestDistance);
        nearestStoreId = this.allStore[0];
        // defaultStoreExists = true;
        // this.shopThisStore(nearestStoreId);
        // Commented for PIM-2155
        // Reopened for PIM-2403
        this.allStore.forEach((item) => {
          if (item.is_default_store) {
            defaultStoreExists = true;
            this.shopThisStore(item, storeOnURL);
          }
        });
      }
      if (!defaultStoreExists) {
        if (nearestStoreId === '') {
          this.locationBox();
        } else if (this.currentLat === '' && this.currentLon === '') {
          this.locationBox();
        } else {
          if (this.allStore?.length) {
            this.shopThisStore(this.allStore[0], storeOnURL);
          }
        }
      }
    }
  }

  currentStoreDetails: any = {};
  alreadyCalledMap: boolean = false;
  getLocalstorageValue() {
    this.curentStoreId = localStorage.getItem('storeId');
    let currentStoreDetails: any = this.allStore?.filter((item: any) => +item.id === +this.curentStoreId);
    if (currentStoreDetails.length > 0) {
      this.currentStoreDetails = currentStoreDetails[0];
      this.curentStoreName = this.currentStoreDetails.storename;
      this.currentStoreOpenTill = this.getClosingTime(this.currentStoreDetails.officetimings);
      localStorage.setItem('openTill', this.currentStoreOpenTill)
    }
    if (Object.keys(this.currentStoreDetails).length > 0 && !this.alreadyCalledMap) {
      // this.setEmbeddedMapUrl();
      this.storeNumber = localStorage.getItem('storenumber');
    }
  }

  setEmbeddedMapUrl() {
    let el: any = document.getElementById('gmap_canvas');
    if (el) {
      if (Object.keys(this.currentStoreDetails).length > 0) {
        let address: any = this.getFullAddress(this.currentStoreDetails);
        el.src = 'https://maps.google.com/maps?q=' + address + '&z=10&ie=UTF8&iwloc=&output=embed';
        this.alreadyCalledMap = true;
      }
    }
    else if (this.isMapClicked) {
      setTimeout(() => {
        this.setEmbeddedMapUrl();
      }, 100);
    }
  }

  web_nav() {
    const element = document.getElementById('web-nav');
    element.classList.toggle('show');
  }

  locationBox() {
    const element = document.getElementById('Location-Box');
    element.classList.toggle('active');
  }

  storeBox() {
    const element = document.getElementById('Store-Box');
    element.classList.toggle('active');
  }
  cartBox() {
    var element = document.getElementById("Cart-Box");
    element.classList.toggle("active");
  }
  closeBox() {
    var e = document.getElementById("Location-Box");
    e.classList.remove("active");
    var elements = document.getElementById("Store-Box");
    elements.classList.remove("active");
    var element = document.getElementById("Cart-Box");
    element.classList.remove("active");
  }

  searchBar() {
    const searchBarContainer = document.getElementsByClassName(
      'searchBar'
    ) as HTMLCollectionOf<HTMLElement>;
    searchBarContainer[0].classList.toggle('active');
  }

  @HostListener('window:scroll', ['$event'])
  /*checkScroll() {
    this.cSp = window.pageYOffset;
    if (this.cSp > 20) {
      this.isSticky = this.cSp < this.pSp;
    } else {
      this.isSticky = false;
    }
    this.pSp = this.cSp;
  }*/

  checkScroll() {
    this.cSp = window.pageYOffset;
    if (this.cSp > 40) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  getClosingTime(officetimings) {
    if (!Array.isArray(officetimings)) {
      // handle invalid input, e.g., throw an error or return a default value
      return '';
    }

    const currentDate = new Date();
    const currentDay = currentDate.getDay() + 1;

    const closingTimeItem = officetimings.find(item => item.day === currentDay);

    return closingTimeItem ? closingTimeItem.closing_time : '';
  }


  getFullAddress(item: any) {
    let fullAddress = '';
    let fullAddressArray = [
      (
        (item.address !== '' && item.address !== null && item.address !== 'null'
          ? item.address + ' '
          : '') +
        (item.city !== '' && item.city !== null && item.city !== 'null'
          ? item.city
          : '')
      ).trim(),
      (
        (item.state_name !== '' &&
          item.state_name !== null &&
          item.state_name !== 'null'
          ? item.state_name + ' '
          : '') +
        (item.zipcode !== '' && item.zipcode !== null && item.zipcode !== 'null'
          ? item.zipcode
          : '')
      ).trim(),
      // item.country_name !== '' &&
      //   item.country_name !== null &&
      //   item.country_name !== 'null'
      //   ? item.country_name
      //   : '',
    ];
    fullAddressArray = fullAddressArray?.filter(function (e) {
      return e != '';
    });
    fullAddress = fullAddressArray.join(', ');
    return fullAddress;
  }

  getStore() {
    this.currentLat = '';
    this.currentLon = '';
    this.commonService
      .getAllData({
        tableId: 'allstoreslist',
        params: {
          status: 'Active',
          limit: '100',
          offset: '0',
          search: this.search,
          sorton: 'id',
          sorttype: 'asc',
        },
      })
      .subscribe((data: any) => {
        if (data) {
          this.allStore = data[0].data;
          this.cartService.setAllsoreData(data[0].data);
          this.calculateDistanceOfStore();
          this.locationWiseStoreCheck();
          this.jsonLdForStores();
        }
      }, (error) => console.error(error));
  }

  jsonLdForStores(data?: any) {
    let allStoreData: any[] = [];
    this.allStore.forEach((ele: any, i: number) => {
      let timeStr = ''
      ele.officetimings.forEach((x: any) => {
        timeStr += (x.day_name.slice(0, 2) + ' ' + (x.opening_time ?? '8:00 AM') + '-' + (x.closing_time ?? '6:00 PM') + ' ');
      })
      allStoreData.push({
        "@type": "LocalBusiness",
        "name": ele.storename,
        "logo": "https://images.buchheits.com/assets/images/logo.png",
        "image": this.getImageUrl(ele?.officeimage ?? '', true),
        "description": "Welcome to Buchheits where we help you Explore the Possibilities of a Simpler Life.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": ele.address,
          "addressLocality": ele.city,
          "addressRegion": ele.state_name,
          "postalCode": ele.zipcode
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": ele.latitude,
          "longitude": ele.longitude
        },
        "hasMap": `http://maps.google.com/?q=${this.getFullAddress(ele)}`,
        "openingHours": timeStr
      })
    });
    const jsonLdData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Buchheit",
      "location": allStoreData
    };
    // Find the existing JSON-LD script element
    const existingJsonLd = document.querySelector('#stores');
    // If an existing JSON-LD script element was found, remove it from the DOM
    if (existingJsonLd) {
      existingJsonLd.remove();
    }
    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.id = 'stores';
    script.text = JSON.stringify(jsonLdData);
    this.renderer.appendChild(document.head, script);
  }


  calculateDistanceOfStore() {
    let locationOn: boolean = false;
    if (this.currentLat !== '' && this.currentLon !== '') {
      locationOn = true;
    }
    let radians = function (degree: number) {
      // degrees to radians
      let rad: number = (degree * Math.PI) / 180;
      return rad;
    };
    const haversine = (
      lat1: number,
      lon1: number,
      lat2: number,
      lon2: number
    ) => {
      let dlat, dlon, a, c, R: number;
      //R = 6372.8; // km
      R = 3959.8; // mile
      dlat = radians(lat2 - lat1);
      dlon = radians(lon2 - lon1);
      lat1 = radians(lat1);
      lat2 = radians(lat2);
      a =
        Math.sin(dlat / 2) * Math.sin(dlat / 2) +
        Math.sin(dlon / 2) *
        Math.sin(dlon / 2) *
        Math.cos(lat1) *
        Math.cos(lat2);
      c = 2 * Math.asin(Math.sqrt(a));
      return R * c;
    };
    const keys = Object.keys(this.allStore);
    let count: number = keys.length;
    for (let _i = 0; _i < count; _i++) {
      let _dummy: number = haversine(
        this.currentLat,
        this.currentLon,
        this.allStore[_i].latitude,
        this.allStore[_i].longitude
      );
      this.allStore[_i].nearestDistance = _dummy;
      this.allStore[_i].locationOn = locationOn;
    }
  }



  getMapImageUrl(imageName: string): string {
    const type = this.tabNav ? 'desktop' : this.mobileNav ? 'mobile' : 'desktop';
    return this.utilityService.getImage(`${imageName}.png`, `mapImage/${type}`);
  }

  getImageUrl(imageName: string, status: boolean): string {
    this.imageWidth = 500;
    this.imageHeight = 300;
    return this.utilityService.getLazyImage(imageName, `party`, status, `${this.imageWidth}x${this.imageHeight}`);
  }

  getHeaderImages(imageName: string, moduleName: string, status: boolean, width?: number, height?: number) {
    height = height || 100;
    width = width || 100;
    return this.utilityService.getLazyImage(imageName, moduleName, status, `${width}x${height}`);
  }

  searchStore(event: any) {
    this.search = event.target.value.trim();
    //this.searchStoreString.next(this.search);
    this.getStore();
  }

  changeStoreOnBookingCart(item: any) {
    let booking_flag: boolean = JSON.parse(localStorage.getItem('booking_flag'));
    if (!booking_flag) {
      this.shopThisStore(item);
      return;
    }
    if (this.router.url.includes('circular')) {
      window.location.reload()
    }
    this.getLoginsubscriptiondata();
    if (!this.isloggedin) {
      let cartDetailsStorage: any = [];
      localStorage.setItem('cartDetailsStorage', JSON.stringify(cartDetailsStorage));
      this.commonService.getCartItem();
    }

    localStorage.setItem('storeId', item.id);
    localStorage.setItem('storenumber', item.storenumber);
    localStorage.setItem('businessUnitId', item.businessunit);
    localStorage.setItem('storeName', item.storename);
    if (this.router.url.includes('/checkout')) {
      this.router.navigate(['/cart']);
    }
    this.getNavigation.emit();
    this.dataApiService.reloadCurrentRoute();

    const element1 = document.getElementById('Location-Box');
    element1?.classList?.remove('active');

    const element2 = document.getElementById('Store-Box');
    element2?.classList?.remove('active');
    this.alreadyCalledMap = false;
  }

  shopThisStore(item: any, fromURL?: boolean) {
    /**const booking_flag = localStorage.getItem('booking_flag'); */
    if ((!item?.length || fromURL)) {
      if (+item.id !== +this.curentStoreId) {
        let cartProducts: any = this.cartService.getCartItemArray();

        if (cartProducts.length > 0) {
          const dialogRef = this.dialog.open(AlertModalComponent, {
            panelClass: ['pop-up', 'sm-pop'],
            data: {
              displayTitle: 'Changing Shopping Location', displayMsg: `You already have items in your cart for this store. Do you want to abandon your cart and shop at another location? Note: Your cart will be reset.`, confirmButtonText: 'Reset Cart & Change Store'
            }
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            this.cartService.storeChangedManually = true;
            if (result === "success") {
              this.changeStore(item);
            }
          }, (error) => console.error(error));
        }
        else {
          this.changeStore(item);
        }
      }
    } else {
      setTimeout(() => {
        const booking_flag = JSON.parse(localStorage.getItem('booking_flag'));
        if (!booking_flag && +item[0].id !== +this.curentStoreId) {
          let cartProducts: any = this.cartService.getCartItemArray();

          const currentStore = localStorage.getItem('storeName');
          const currentStoreId = localStorage.getItem('storeId');
          if (cartProducts) {
            const dialogRef = this.dialog.open(AlertModalComponent, {
              panelClass: ['pop-up', 'sm-pop'],
              data: {
                displayTitle: 'Shopping Location Changed!', displayMsg: `You already have items in your cart for ${item[0].storename} store. Do you want to abandon your cart and shop at ${currentStore}? Note: Your cart will be reset.`, confirmButtonText: 'Reset Cart'
              }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
              // this.firstTimeCalled = false;
              if (result === "success") {
                const savedStoreArray = this.allStore?.filter((a: any) => a.id == currentStoreId);
                this.changeStore(savedStoreArray[0]);
              } else {
                localStorage.setItem('storeId', item[0].id);
                localStorage.setItem('storenumber', item[0].storenumber);
                localStorage.setItem('businessUnitId', item[0].businessunit);
                localStorage.setItem('storeName', item[0].storename);
                this.commonService.openSnackbar(`Your Location is set to ${item[0].storename} store.`);
                if (this.router.url.includes('/checkout')) {
                  this.router.navigate(['/cart']);
                }
              }
            }, (error) => console.error(error));
          }
        }
      }, 1000)
    }
  }

  get cartData_() {
    return this.cartService.getCartItemArray();
  }

  changeStore(item: any) {
    /**
     * Remove cart item after store change
     */
    if (this.router.url.includes('circular')) {
      window.location.reload()
    }
    this.getLoginsubscriptiondata();
    let cartProducts: any = this.cartService.getCartItemArray();
    if (!this.isloggedin) {
      let cartDetailsStorage: any = [];
      localStorage.setItem('cartDetailsStorage', JSON.stringify(cartDetailsStorage));
      this.commonService.getCartItem();
    }
    else {
      if (cartProducts.length > 0) {
        cartProducts.forEach((item: any) => {
          this.commonService.productRemoveFromCartAfterAddedToWishList(item);
        });
      }
    }

    localStorage.setItem('storeId', item.id);
    localStorage.setItem('storenumber', item.storenumber);
    localStorage.setItem('businessUnitId', item.businessunit);
    localStorage.setItem('storeName', item.storename);
    if (this.router.url.includes('/checkout')) {
      this.router.navigate(['/cart']);
    }
    this.getNavigation.emit();
    this.dataApiService.reloadCurrentRoute();

    const element1 = document.getElementById('Location-Box');
    element1?.classList?.remove('active');

    const element2 = document.getElementById('Store-Box');
    element2?.classList?.remove('active');
    this.alreadyCalledMap = false;
    this.changeMegaMenu.emit();
  }

  onLoginPopupClick() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: ['pop-up', 'md-pop'],
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataApiService.reloadCurrentRoute();
      }
    });

  }
  onHeadIconClick() {
    if (this.isloggedin) this.onNavigateto('yourprofile');
    else this.onLoginPopupClick();
  }

  goHome(event: any) {
    event.preventDefault();
    this.router.navigate([``])
    // this.checkParentSlugIsActiveOrNot();
  }

  onLogout() {
    // this.commonService.cartCount = 0;
    this.commonService.cartDetails = [];
    this.auth.authToken.next(Object.assign({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: '', auth: '', username: '', customer_id: '', customerorder_id: '', phone: '', buchheit_token: '' }));
    this.auth.auth = '';
    this.auth.removeUserCredential();
    this.commonService.setCartItems({ 'cartCount': 0, 'cartDetails': [] });
    //this.auth.AuthLogout();
    localStorage.removeItem('customerorder_id');
    localStorage.removeItem('cartDetailsStorage');
    localStorage.removeItem('is_stock');
    localStorage.removeItem('booking_flag');
    localStorage.removeItem('booking_store');
    localStorage.removeItem('pickup_window_id');
    this.cartService.setIsInStock(null);
    if(this.router.url.includes('/search')) {
      window.location.reload();
    }
  }

  onNavigateto(type: string) {
    if (type == 'yourprofile') {
      this.router.navigate(['/user/yourprofile']);
    } else if (type == 'changepassword') {
      this.router.navigate(['/user/changepassword']);
    } else if (type == 'yourorder') {
      this.router.navigate(['/user/yourorder']);
    } else if (type == 'yourwishlist') {
      this.router.navigate(['/user/yourwishlist']);
    } else if (type == 'youraddress') {
      this.router.navigate(['/user/youraddress']);
    } else if (type == 'yourrebates') {
      this.router.navigate(['/user/yourrebates']);
    } else if (type == 'yourgiftcards') {
      this.router.navigate(['user/yourgiftcards']);
    } else if (type == 'orderhistory') {
      this.router.navigate(['user/orderhistory']);
    }
    // if (window.innerWidth < 1100) {
    localStorage.setItem('currentAccountUrl', type)
    // }
  }

  ngOnDestroy() {
    if (this.usersubscription) {
      this.usersubscription.unsubscribe();
    }
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }
  isCheckoutPage() {
    return this.router.url.includes('/checkout');
  }
  isGuestCheckoutPage(): boolean{
    const url = this.router.url;
    return url.includes('/guest-user/checkout-billing') || url.includes('/guest-user/order-complete') || url.includes('/guest-user/order-overview');
  }
  isLocationBarVisible() {
    // if (this.router.url.includes('/checkout') || this.router.url.includes('/listing') || this.router.url.includes('/cart') || this.router.url.includes('/p/') || this.router.url.includes('/giftcards/giftcard-shipping') || this.router.url.includes('/giftcards/giftcard-billing')) {
    //   return true;
    // }
    // return false;
    if (this.router.url == '/' || this.router.url == '/home') {
      return true;
    }
    return false;
  }
  isGiftCardPage() {
    return this.router.url.includes('/giftcards/giftcard-shipping') || this.router.url.includes('/giftcards/giftcard-billing');
  }

  goOrderReviewPage() {
    if (this.isCheckoutPage()) {
      if (this.pageRoot == 'billing' || this.pageRoot == 'order') {
        this.router.navigate(['/checkout/order-review']);
      }
    }
  }
  goBillingPaymentPage() {
    if (this.isCheckoutPage()) {
      if (this.pageRoot == 'order') {
        this.router.navigate(['/checkout/checkout-billing']);
      }
    }
  }
  goGiftcardReviewPage() {
    if (this.isGiftCardPage) {
      if (this.pageRoot == 'giftcard-billing') {
        this.router.navigate(['/giftcards/giftcard-shipping']);
      }
    }
  }

  get isHomePage() {
    return this.router.url === '/' || this.router.url === '/home' || this.router.url.includes('/cart') || this.router.url.includes('/shop/') || this.router.url.includes('/listing/') || this.router.url.includes('/p/');
  }

  resize(): void {
    if (window.innerWidth < 901) {
      this.mobileNav = true;
    } else {
      this.mobileNav = false;
    }

    if (window.innerWidth > 500) {
      this.tabNav = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resize();
  }

  isLoggedin: boolean = false;
  goToYourRebates() {
    this.auth.authToken.subscribe((value: any) => {
      this.isLoggedin = value.isLoggedin === 'true' ? true : false;
    });
    if (this.isLoggedin) {
      this.router.navigate(['/user/yourrebates']);
    }
    else {
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: ['pop-up', 'md-pop'],
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate(['/user/yourrebates']);
        }
      });
    }
  }

  getTopHeaderItems() {
    this.commonService.getNavigationItem().subscribe((value: any) => {
      if (value && value.length > 0 && value[0].data && value[0].data.length > 0) {
        this.functionId = value[0].nav_menu_id;
        value[0].data.forEach((e: any, i: number) => {
          if (e.is_default) {
            this.navMenuItem = e;
            e.navigation_item.forEach((element: any) => {
              if (element.is_top_link) {
                this.navHeaderMenu.push(element);
              }
            });
          }
        })
      }
    }, (error) => console.error(error));
  }

  menuClick(menu: any) {
    let slug_or_id: any = menu?.navigationitem_slug ? menu?.navigationitem_slug : menu?.navigationitem_value;
    if (menu.navigationitemtype.toString() === '5') {
      this.commonService.getNavigationURL().subscribe((value: any) => {
        if (value && value.data && value.data.length > 0) {
          value.data.forEach((e: any) => {
            if (e.navigationitemurl_id === menu.navigationitem_value) {
              (e.navigation_url.includes('/giftcards') && window.location.hostname == 'localhost') ? this.router.navigate([`/giftcards`]) : window.open(e.navigation_url, '_self')
            }
          })
        }
      }, (error) => console.error(error));
    } else if (menu.navigationitemtype.toString() === '4') {
      this.commonService.activeMenu(this.functionId);
      this.router.navigate([`/listing/${slug_or_id}`]);
      //this.router.navigate([`/listing/${menu.navigationitem_label.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")}`]);
    } else if (menu.navigationitemtype.toString() === '2') {
      this.router.navigateByUrl('/shop', { skipLocationChange: true }).then(() => {
        this.router.navigate([`/${slug_or_id}`]);
      });
    } else if (menu.navigationitemtype.toString() === '3') {
      this.router.navigate([`/p/${slug_or_id}`]);
    } else if (menu.navigationitemtype.toString() === '1') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['item-group', slug_or_id]);
      });
    }
  }
  
  returnClassElement(activeTab: string): string {
    switch (activeTab) {
      case '1':
        if (this.pageRoot === 'guestuser-overview') return 'active';
        if (this.pageRoot === 'guestuser-billing' || this.pageRoot === 'guestuser-ordercomplete') return 'done';
        return '';
  
      case '2':
        if (this.pageRoot === 'guestuser-billing') return 'active';
        if (this.pageRoot === 'guestuser-ordercomplete') return 'done';
        return '';
  
      case '3':
        return this.pageRoot === 'guestuser-ordercomplete' ? 'active' : '';
  
      default:
        return '';
    }
  }
  gotoGuestOrderOverview(){
    if (this.isGuestCheckoutPage()) {
      if (this.pageRoot == 'guestuser-billing') {
        this.router.navigate(['/guest-user/order-overview']);
      }else{
        this.router.navigate(['/cart']);
      }
    }
  }
  trackOrderPage(): boolean{
     const url = this.router.url;
    return url.includes('/guest-user/track-order');
  }
  
}