import { Injectable } from '@angular/core';
declare var gtag: Function;

declare global {
	interface Window { dataLayer: any[]; }
}


@Injectable({
	providedIn: 'root'
})
export class GoogleAnalyticsService {
	data = {
		8: {
		  price: "8_house_springs_price",
		  inventory: "8_house_springs_inventory"
		},
		7: {
		  price: "7_jacksonville_price",
		  inventory: "7_jacksonville_inventory"
		},
		6: {
		  price: "6_herculaneum_price",
		  inventory: "6_herculaneum_inventory"
		},
		5: {
		  price: "5_greenville_price",
		  inventory: "5_greenville_inventory"
		},
		4: {
		  price: "4_jackson_price",
		  inventory: "4_jackson_inventory"
		},
		3: {
		  price: "3_centralia_price",
		  inventory: "3_centralia_inventory"
		},
		32: {
		  price: "32_north_platte_price",
		  inventory: "32_north_platte_inventory"
		},
		31: {
		  price: "31_beatrice_price",
		  inventory: "31_beatrice_inventory"
		},
		30: {
		  price: "30_murray_price",
		  inventory: "30_murray_inventory"
		},
		2: {
		  price: "2_perryville_price",
		  inventory: "2_perryville_inventory"
		},
		29: {
		  price: "29_pocahontas_price",
		  inventory: "29_pocahontas_inventory"
		},
		27: {
		  price: "27_paragould_price",
		  inventory: "27_paragould_inventory"
		},
		26: {
		  price: "26_jonesboro_price",
		  inventory: "26_jonesboro_inventory"
		},
		25: {
		  price: "25_columbia_north_price",
		  inventory: "25_columbia_north_inventory"
		},
		24: {
		  price: "24_columbia_south_price",
		  inventory: "24_columbia_south_inventory"
		},
		23: {
		  price: "23_fulton_price",
		  inventory: "23_fulton_inventory"
		},
		22: {
		  price: "22_kirksville_price",
		  inventory: "22_kirksville_inventory"
		},
		21: {
		  price: "21_blue_springs_price",
		  inventory: "21_blue_springs_inventory"
		},
		20: {
		  price: "20_jefferson_city_price",
		  inventory: "20_jefferson_city_inventory"
		},
		1: {
		  price: "1_sparta_price",
		  inventory: "1_sparta_inventory"
		}
	  };

	constructor() { }
	/**
	   * DoItForItems() is a common function for pushing the datas to dataLayer.
	   * 
	   */
	DoItForItems(dataLayerArray: any, type: string) {
		let datalayerItems: any = [];
	
		const storeId = localStorage.getItem('storeId');
		const storeKey = this.data[storeId]?.price;
	
		dataLayerArray.forEach((b: any, i: number) => {
			const doc = b.Document;
			const price = doc[storeKey]?.[0] || 'NA';
	
			datalayerItems.push({
				'item_id': b.Item?.id || b.DocId,
				'item_name': b.Item?.name || doc.name?.[0] || 'NA',
				'price': b.price || b.regularPrice || price,
				'offer_price': b?.offer_price || 'NA',
				'item_brand': b.brand_name || b.Item?.brand_name || doc.brandname?.[0] || 'NA',
				'item_category': b.primarytaxonomy?.slice(-1)?.[0]?.taxnomyname || doc.category_search?.[0]?.split(',').pop().trim() || 'NA',
				'item_variant': 'NA',
				'item_list_name': type,
				'index': i
			});
		});
	
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			'event': 'view_item_list',
			'ecommerce': {
				'currency': 'USD',
				'page_name': type,
				'items': datalayerItems,
			}
		});
	}
	
	forSingleItemCollection(data: any) {
		if (Object.keys(data).length > 0) {
			window['dataLayer'] = window['dataLayer'] || [];
			window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
			window.dataLayer.push({
				'item_id': data.Item.id,
				'price': data.price,
				'item_brand': data.brand_name ?? 'NA',
				'item_category': data.primarytaxonomy.slice(-1)[0].taxnomyname ?? '',
				'item_variant': 'NA',
				'item_list': 'landing',
				'index': 0
			});
		}
	}
	/**
	 * Call this function when a user clicks on a product link. This function uses the event
	 * callback datalayer variable to handle navigation after the ecommerce data has been sent
	 * to Google Analytics.
	 * @param {Object} productObj An object representing a product.
	 */
	productClick(productObj) {
		const storeId = localStorage.getItem('storeId');
		const storeKey = this.data[storeId]?.price;
		const doc = productObj.attributes || {};
		const price = doc[storeKey]?.[0] || 'NA';
		const item = productObj.Item || {};
	
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
	
		window.dataLayer.push({
			'event': 'select_item',
			'ecommerce': {
				'items': [{
					'item_id': item.id || productObj.id,
					'item_name': item.name || doc.name?.[0] || 'NA',
					'price': productObj.price || productObj.regularPrice || price || 'NA',
					'item_brand': item.brand || doc.brandname?.[0] || 'NA',
					'item_category': (productObj.primarytaxonomy?.slice(-1)?.[0]?.taxnomyname || 
									  doc.category_search?.[0]?.split(',').pop().trim() || 'NA'),
					'item_variant_color': item.is_variant_color === 1,
					'item_variant_size': item.is_variant_size === 1,
				}]
			},
		});
	}
	

	productView(productObj: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'view_item',
			'ecommerce': {
				// 'click': {
					currency: "USD",
					value: productObj.price,
					'items': [{
						'item_name': productObj.Item.name,
						'item_id': productObj.Item.id,
						'price': productObj.price,
						'item_brand': productObj.Item.brand ?? '',
						'item_category': productObj?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
					}]
				// }
			},
		});
	}

	addToCart(productObj: any) {
		// Measure adding a product to a shopping cart by using an 'add' actionFieldObject
		// and a list of productFieldObjects.
		window['dataLayer'] = window['dataLayer'] || [];
		if (productObj?.primarytaxonomy?.slice(-1)[0].taxnomyname !== undefined) window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'add_to_cart',
			'ecommerce': {
				'currency': 'USD',
				'value': (+productObj.offeredperunitprice || +productObj.regularPrice) * +productObj.itemCount,
					'items': [{                        
						'item_name': productObj.Item.name,                   
						'item_id': productObj.Item.id,
						'price': +productObj.offeredperunitprice || +productObj.regularPrice,
						'item_brand': productObj.isRapidUi ?  productObj.Item.brand : productObj.Item.brand_name,
						'item_category': (productObj.primarytaxonomy?.slice(-1)?.[0]?.taxnomyname || 
						productObj.category_search?.[0]?.split(',').pop().trim() || 'NA'),
						'quantity': productObj.itemCount
					}]
			}
		});
	}

	removeFromCart(productObj: any) {
		// Measure the removal of a product from a shopping cart.
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'remove_from_cart',
			'ecommerce': {
				currency: "USD",
				value: (+productObj.offeredperunitprice || +productObj.regularPrice) * +productObj.itemCount,                        
					'items': [{                          
						'item_name': productObj.Item.name,                     
						'item_id': productObj.Item.id,
						'price': productObj.regularPrice || productObj.offeredperunitprice,
						'item_brand': productObj.isRapidUi ?  productObj.Item.brand : productObj.Item.brand_name,
						'item_category': (productObj.primarytaxonomy?.slice(-1)?.[0]?.taxnomyname || 
						productObj.category_search?.[0]?.split(',').pop().trim() || 'NA'),
						'quantity': productObj?.itemCount == 0 ? 1 : productObj?.itemCount
					}]
			}
		});
	}

	promoView(dataObject: any) {
		// An example of measuring promotion views. This example assumes that
		// information about the promotions displayed is available when the page loads.
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'ecommerce': {
				'promoView': {
					'promotions': [                     // Array of promoFieldObjects.
						{
							'id': 'JUNE_PROMO13',            // ID or Name is required.
							'name': 'June Sale',
							'creative': 'banner1',
							'position': 'slot1'
						},
						{
							'id': 'FREE_SHIP13',
							'name': 'Free Shipping Promo',
							'creative': 'skyscraper1',
							'position': 'slot2'
						}]
				}
			}
		});
	}

	checkout(dataObject: any) {
		/**
 * A function to handle a click on a checkout button. This function uses the eventCallback
 * data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
 */
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		let datalayerItems: any = [];
		const data = dataObject.data;
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'item_name': b.Item.name,                      // Name or ID is required.
				'item_id': b.Item.id,
				'price': b.price,
				'item_brand': b.Item.brand ?? '',
				'item_category': b?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
				'quantity': b?.itemCount,
				'index': i
			});
		});
		window.dataLayer.push({
			'event': 'begin_checkout',
			'ecommerce': {
				// 'checkout': {
					currency: "USD",
					value: dataObject.total,
					tax: dataObject.tax,
					'actionField': { 'step': dataObject.step, 'option': 'Card' },
					'items': datalayerItems
				// }
			}
		});
	}
	addToWishlist(dataObject: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		let datalayerItems: any = [];
		const data = [dataObject];
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'item_name': b.Item.name,
				'item_id': b.Item.id,
				'price': +b.offer_price || +b.offeredperunitprice || +b.regularPrice,
				'item_brand': b.isRapidUi ?  b.Item.brand : b.Item.brand_name,
				'item_category': (b.primarytaxonomy?.slice(-1)?.[0]?.taxnomyname || 
				b.category_search?.[0]?.split(',').pop().trim() || 'NA'),
				'index': i
			});
		});
		window.dataLayer.push({
			'event': 'add_to_wishlist',
			'ecommerce': {
				'items': datalayerItems
			}
		});
	}
	addPaymentInfo(dataObject: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		let datalayerItems: any = [];
		const data = dataObject.data;
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'name': b.Item.name,                      // Name or ID is required.
				'id': b.Item.id,
				'price': b.price,
				'brand': b.Item.brand ?? '',
				'category': b?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
				'quantity': b?.itemCount,
				'position': i + 1
			});
		});
		window.dataLayer.push({
			'event': 'add_payment_info',
			'ecommerce': {
				currency: "USD",
				value: 7.77,
				coupon: "SUMMER_FUN",
				payment_type: "Credit Card",
				'products': datalayerItems
			}
		});
	}
	search(data: any) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'search',
			'ecommerce': {
				'search_term': data
			}
		});
	}

	purchase(dataObject: any) {
		// Send transaction data with a pageview if available
		// when the page loads. Otherwise, use an event when the transaction
		// data becomes available.
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		let datalayerItems: any = [];
		const data = dataObject.data;
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'item_name': b.Item.name,                      // Name or ID is required.
				'item_id': b.Item.id,
				'price': b.price,
				'item_brand': b.Item.brand ?? '',
				'item_category': b?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
				'quantity': b?.itemCount,
				'index': i
			});
		});
		window.dataLayer.push({
			'event': 'purchase',
			'ecommerce': {
				'purchase': {
					'transaction_id': dataObject?.transaction_id,
					'affiliation': dataObject?.store,
					'revenue': dataObject?.sub_total,
					'tax': dataObject?.tax,
					'coupon': dataObject?.coupon,
					'value': dataObject?.sub_total,
					'currency': 'USD',
					'items': datalayerItems
				}
			}
		});

	}

	refund(data: any) {
		// Refund an entire transaction by providing the transaction ID. This example assumes the details
		// of the completed refund are available when the page loads:
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'ecommerce': {
				'refund': {
					'actionField': { 'id': 'T12345' }         // Transaction ID. Required for purchases and refunds.
				}
			}
		});
	}


	sendPageview(page: string, title: string, type?: string) {
		// console.log(type);
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'page_view',
			pagePath: page,
			pageType: type ? type : '',
			pageTitle: title,
		});
	}

	sendEvent(event: string, data?: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		let obj = {
			event: event,
			...data
		};
		// console.log(obj);
		window.dataLayer.push(obj);
	}

	locationFlyoutClicked(data: any, locationUri: any,allStores:number = 0) {
		// const isLoggedin = localStorage.getItem('isLoggedin');
		if (data) {
			const locationUrl = `http://maps.google.com/?q=${locationUri}`;
			let user = localStorage.getItem('userfirstname') + ' ' + localStorage.getItem('userlastname');
			if(!localStorage.getItem('userfirstname')) user = 'Guest User';
			window['dataLayer'] = window['dataLayer'] || [];
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push({
				event: 'location_flyout_click',
				'user': user ,
				'sum_of_all_stores': allStores,
				'store_name': data.storename,
				'location_url': locationUrl,
				'address': data.address ?? '',
				'city': data.city ?? '',
				'state': data.state_name ?? '',
				'zip': data.zipcode ?? ''
			});
		}
	}

	registraionSuccess(data:any) {
		const user = data.consumer_name ||  data.firstname + ' ' + data.lastname;
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'registration-success',
			ecommerce: {
				user: user,
			    email: data.email ?? '',
			}
		});
	}
}
