<div class="login-wrapper">
  <div class="login-logo">
    <a href="/" [routerLink]="['/']">
      <img [defaultImage]="getHeaderImages('login-web.png', 'assets/images',  true, 270, 569)"
        [lazyLoad]="getHeaderImages('login-web.png', 'assets/images', false, 270, 569)" alt="images" width="100"
        height="100" class="d-none d-md-block">
      <img [defaultImage]="getHeaderImages('login-mob.png', 'assets/images',  true, 296, 60)"
        [lazyLoad]="getHeaderImages('login-mob.png', 'assets/images', false, 296, 60)" alt="images" width="100"
        height="100" class="d-md-none">
    </a>
  </div>
  <div class="login-from">
    <div class="login-close">
      <button aria-label="button" mat-icon-button (click)="closePopup()" class="sm-btn">
        <em class="icon-close"></em>
      </button>
    </div>
    <div class="login-box" [ngClass]="login ? 'arive' : 'leave'">
      <div class="login-heading">
        Sign In to your Buchheit Buyer’s Club Account
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="loginusername" required="" (keypress)="preventWhitespace($event)" />
          <mat-error *ngIf="loginusername.hasError('required')">
            Email is required.
          </mat-error>
          <mat-error
            *ngIf="(loginusername.hasError('email') || loginusername.hasError('pattern')) && !loginusername.hasError('required')">
            Please enter a valid email address.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" required="" [formControl]="loginpassword" />
          <button aria-label="button" mat-icon-button matSuffix type="button" (click)="hide = !hide"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon aria-label="icon">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-error *ngIf="loginpassword.hasError('required')">
            Password is required.
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="success">
          <div class="msg success">You have successfully signed in.</div>
        </ng-container>
        <ng-container *ngIf="error">
          <div class="msg danger">{{message}}</div>
        </ng-container>

        <div>
          <mat-checkbox color="accent" (change)="onSelectStayLogin($event)" (click)="$event.stopPropagation()"
            [checked]="isstaylogin">Remember Me</mat-checkbox>
        </div>
        <div class="login-text">
          By continuing, you agree to Buchheit's <a [href]="[termsAndPrivacyUrl()]">Terms of Use & Privacy Policy</a>.
        </div>
        <div class="clear20"></div>
        <ng-container *ngIf="!process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block" type="submit">
            Sign In
          </button>
        </ng-container>
        <ng-container *ngIf="process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block" disabled="">
            <svg class="lds-typing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
              <circle cx="24.5" cy="48.4263" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.5s">
                </animate>
              </circle>
              <circle cx="41.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.375s"></animate>
              </circle>
              <circle cx="58.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.25s">
                </animate>
              </circle>
              <circle cx="75.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.125s"></animate>
              </circle>
            </svg>
          </button>
        </ng-container>
        <button aria-label="button" mat-stroked-button color="accent" class="btn-block"
          (click)="register = !register; login = !login;error=false;">
          Don't have an account? Register
        </button>

        <button aria-label="button" mat-stroked-button color="accent" class="btn-block"
          (click)="guestCheckout = !guestCheckout; login = !login;error=false;" *ngIf="checkoutAsGuest()">
          Checkout as a Guest
        </button>

        <div class="text-center mt-3">
          <button aria-label="button" mat-button color="primary" (click)="forget = !forget; login = !login;error=false;"
            class="sm-btn">
            Forgot Password?
          </button>
        </div>
      </form>
    </div>

    <div class="login-box" [ngClass]="forget ? 'arive' : 'leave'">
      <div class="login-heading">
        Forgot Password of your Buchheit Buyer’s Club Account?
      </div>
      <div class="login-text text-center">
        Enter the email address to reset your password. We will email you a link to reset your password. The link will
        expire in 48 hours.
      </div>
      <div class="clear20"></div>

      <form [formGroup]="forgotpasswordform" (ngSubmit)="onForgotpassword()">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="forgotusername" required="" (keypress)="preventWhitespace($event)" />
          <mat-icon aria-label="icon" class="material-icons-outlined" matSuffix>email</mat-icon>
          <mat-error *ngIf="forgotusername.hasError('required')">
            Email is required.
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="error">
          <div class="msg danger">{{message}}</div>
          <div class="clear20"></div>
        </ng-container>
        <div class="clear10"></div>
        <ng-container *ngIf="!process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block login" type="submit"
            [disabled]="forgotpasswordform.invalid">
            Submit
          </button>
        </ng-container>
        <ng-container *ngIf="process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block" disabled="">
            <svg class="lds-typing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
              <circle cx="24.5" cy="48.4263" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.5s">
                </animate>
              </circle>
              <circle cx="41.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.375s"></animate>
              </circle>
              <circle cx="58.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.25s">
                </animate>
              </circle>
              <circle cx="75.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.125s"></animate>
              </circle>
            </svg>
          </button>
        </ng-container>
        <div class="clear10"></div>
        <button aria-label="button" mat-button color="accent" class="btn-block"
          (click)="forget = !forget; login = !login;error=false;">
          Back to Sign In
        </button>
      </form>
    </div>

    <div class="login-box" [ngClass]="register ? 'arive' : 'leave'">
      <div class="login-heading">
        Sign up for a Buchheit Buyer’s Club Account
      </div>
      <form [formGroup]="registerform" (ngSubmit)="onRegister()">
        <div class="row gutter20">
          <div class="col-md-6">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>First Name</mat-label>
              <input matInput [formControl]="firstname" required="" />
              <mat-error align="end" *ngIf="firstname.hasError('required')">
                First Name is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Last Name</mat-label>
              <input matInput [formControl]="lastname" required="" />
              <mat-error align="end" *ngIf="lastname.hasError('required')">
                Last Name is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row gutter20">
          <div class="col-md-6">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Email Address</mat-label>
              <input matInput [formControl]="username" required="" (keypress)="preventWhitespace($event)" />
              <mat-error
                *ngIf="(username.hasError('email') || username.hasError('pattern')) && !username.hasError('required')">
                Please enter a valid email address.
              </mat-error>
              <mat-error *ngIf="username.hasError('required')">
                Email Address is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Mobile Number</mat-label>
              <span matPrefix>+1 &nbsp;</span>
              <input matInput [formControl]="phone" appOnlynumber [onlynumber]="true"
                (keypress)="getPhoneValue($event)" />
              <mat-error *ngIf="username.hasError('required')">
                Mobile Number is required.
              </mat-error>
              <mat-error align="end" *ngIf="phone.hasError('pattern')">
                Please enter a valid phone number.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Street Address</mat-label>
          <input matInput [formControl]="streetAddress" placeholder="Enter Street Address" required />
          <mat-error *ngIf="streetAddress.hasError('required')">
            Street Address is required.
          </mat-error>
        </mat-form-field>
        <div class="row gutter20">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input matInput placeholder="Enter City" [formControl]="city" maxlength="30" required>
              <mat-error *ngIf="city.hasError('required')">
                City is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <ng-select [notFoundText]="'No Items Found'" [items]="selectedState" bindLabel="name"
              [selectableGroup]="true" [selectableGroupAsModel]="false" [multiple]="false" [closeOnSelect]="true"
              bindValue="id" formControlName="state" [closeOnSelect]="true" [hideSelected]="true" appendTo=""
              placeholder="State *" (focus)="oncalldropdownvalue('state')" required>
            </ng-select>
            <mat-error *ngIf="state.hasError('required') && state.touched">
              State is required.
            </mat-error>
          </div>
        </div>
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Zipcode</mat-label>
          <input matInput placeholder="Enter Zipcode" required [formControl]="postalcode" maxlength="10" minlength="5"
            type="text" />
          <mat-error *ngIf="postalcode.hasError('required')">
            Zipcode is required.
          </mat-error>
          <mat-error *ngIf="postalcode.hasError('minlength')">
            Minimum length 5.
          </mat-error>
        </mat-form-field>

        <div class="row gutter20">
          <div class="col-md-6">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Password</mat-label>
              <input matInput [type]="passHide ? 'password' : 'text'" [formControl]="password" required="" />
              <button aria-label="button" mat-icon-button matSuffix type="button" (click)="passHide = !passHide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passHide">
                <mat-icon aria-label="icon">{{ passHide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
              <mat-error *ngIf="password.hasError('required')">
                Password is required.
              </mat-error>
              <mat-error *ngIf="password.hasError('pattern')">
                Passwords must contain all of the following:<br>
                • Minimum 8 characters<br>
                • 1 Letter<br>
                • 1 Number <br>
                • 1 of these special characters: ! @ # $ % ^ & *
              </mat-error>

            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Confirm Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" required [formControl]="confirmpassword" />
              <button aria-label="button" mat-icon-button matSuffix type="button" (click)="hide = !hide"
                [attr.aria-label]="'Hide Confirm password'" [attr.aria-pressed]="hide">
                <mat-icon aria-label="icon">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
              <mat-error *ngIf="confirmpassword.hasError('required')">
                Confirm Password is required.
              </mat-error>
              <mat-error *ngIf="confirmpassword.value!=='' && !isPasswordSame">Password and Confirm Password must be
                match.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="error">
          <div class="msg danger">{{message}}</div>
        </ng-container>
        <div class="clear20"></div>
        <div>
          <mat-checkbox color="accent" formControlName="opted_nightly_email">Enable email notifications for all
            Buchheit orders.</mat-checkbox>
        </div>
        <div class="login-text">
          By continuing, you agree to Buchheit's <a [href]="[termsAndPrivacyUrl()]">Terms of Use & Privacy Policy</a>.
        </div>
        <div class="clear20"></div>
        <ng-container *ngIf="!process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block" type="submit">
            Register
          </button>
        </ng-container>
        <ng-container *ngIf="process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block" disabled="">
            <svg class="lds-typing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
              <circle cx="24.5" cy="48.4263" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.5s">
                </animate>
              </circle>
              <circle cx="41.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.375s"></animate>
              </circle>
              <circle cx="58.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.25s">
                </animate>
              </circle>
              <circle cx="75.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.125s"></animate>
              </circle>
            </svg>
          </button>
        </ng-container>
        <button aria-label="button" mat-stroked-button color="accent" class="btn-block"
          (click)="register = !register; login = !login; error=false;">
          Already have an account? Sign In
        </button>
      </form>
    </div>

    <div class="login-box" [ngClass]="guestCheckout ? 'arive' : 'leave'">
      <div class="login-heading">
        Checkout as a Guest
      </div>
      <div class="clear20"></div>

      <form [formGroup]="guestCheckoutForm" (ngSubmit)="onGuestCheckoutSubmit()">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="guestuseremail" required="" (keypress)="preventWhitespace($event)" />
          <mat-icon aria-label="icon" class="material-icons-outlined" matSuffix>email</mat-icon>
          <mat-error *ngIf="guestuseremail.hasError('required')">
            Email is required.
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="error">
          <div class="msg danger">{{message}}</div>
          <div class="clear20"></div>
        </ng-container>

        <div class="login-text">
          By continuing, you agree to Buchheit's <a [href]="[termsAndPrivacyUrl()]">Terms of Use & Privacy Policy</a>.
        </div>
        <div class="clear10"></div>
        <ng-container *ngIf="!process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block login" type="submit"
            [disabled]="guestCheckoutForm.invalid">
            Submit
          </button>
        </ng-container>
        <ng-container *ngIf="process">
          <button aria-label="button" mat-flat-button color="accent" class="btn-block" disabled="">
            <svg class="lds-typing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
              <circle cx="24.5" cy="48.4263" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.5s">
                </animate>
              </circle>
              <circle cx="41.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.375s"></animate>
              </circle>
              <circle cx="58.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.25s">
                </animate>
              </circle>
              <circle cx="75.5" cy="62.5" r="6">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                  repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s"
                  begin="-0.125s"></animate>
              </circle>
            </svg>
          </button>
        </ng-container>
        <div class="clear10"></div>
        <button aria-label="button" mat-button color="accent" class="btn-block"
          (click)="login = !login; guestCheckout = !guestCheckout; error=false;">
          Back to Sign In
        </button>
      </form>
    </div>
  </div>
</div>