<div class="Buchheit-top" *ngIf="!isCheckoutPage() && !isGiftCardPage() && !isGuestCheckoutPage()">
	<ul>
		<li matRipple *ngFor="let menu of navHeaderMenu;let i=index" (click)="menuClick(menu)">
			<a href="/" (click)="$event.preventDefault()">{{menu.navigationitem_label}}</a>
		</li>
	</ul>
</div>
<header class="Buchheit-header" [ngClass]="{'sticky' : isSticky}" [ngClass]="LocationTimingBox ? 'onTop' : ''"
	*ngIf="!isCheckoutPage() && !isGiftCardPage() && !isGuestCheckoutPage()">
	<div class="Buchheit-inner-header">
		<div class="mob-nav">
			<button aria-label="button" mat-icon-button (click)="web_nav()">
				<mat-icon aria-label="icon" aria-label="menu">menu</mat-icon>
			</button>
		</div>
	
		<h1 class="Buchheit-inner-header-logo">
			<a href="/" [routerLink]="['/']">
				<img height="100" width="100" alt="Logo"
					[defaultImage]="getHeaderImages('logo.png', 'assets/images',  true, 296, 60)"
					[lazyLoad]="getHeaderImages('logo.png', 'assets/images', false, 296, 60)" alt="Logo">
	
			</a>
		</h1>
		<div class="Buchheit-inner-header-location">
			<div class="btn-group">
				<button aria-label="button" mat-stroked-button color="primary" (click)="locationBox()">
					<div class="stoer-name">
						<mat-icon aria-label="icon" aria-label="location" color="accent" class="material-icons-outlined">
							location_on</mat-icon>
						{{curentStoreName}}
					</div>
				</button>
				<button aria-label="button" mat-stroked-button color="primary"
					(click)="isMapClicked = true; LocationTimingBox = !LocationTimingBox">
					<div class="stoer-name">
						Open until <br>{{currentStoreOpenTill}}
					</div>
				</button>
			</div>
			<div class="store-timimg" [ngClass]="LocationTimingBox ? 'active' : ''">
				<div class="store-heading">
					<button aria-label="button" mat-icon-button (click)="LocationTimingBox = !LocationTimingBox"
						class="d-md-none">
						<mat-icon aria-label="icon" aria-label="left">chevron_left</mat-icon>
					</button>
					{{curentStoreName}}
					<button aria-label="button" mat-icon-button (click)="LocationTimingBox = !LocationTimingBox"
						class="ml-auto d-none d-md-block">
						<mat-icon aria-label="icon" aria-label="close">close</mat-icon>
					</button>
				</div>
				<div class="store-wrapper">
					<div class="store-timimg-details">
						<span>
							<mat-icon aria-label="icon" aria-label="schedule">schedule</mat-icon>
							Open until {{currentStoreOpenTill}}
						</span>
						<span *ngIf="currentStoreDetails.phonenumber">
							<mat-icon aria-label="icon" aria-label="call">call</mat-icon>
							<a href="tel:{{currentStoreDetails?.phonenumber}}">{{currentStoreDetails?.phonenumber || 'Contact us'}}</a>
						</span>
						<span>
							<mat-icon aria-label="icon" aria-label="place" class="material-icons-outlined">place</mat-icon>
							<a  href="http://maps.google.com/?q={{getFullAddress(currentStoreDetails)}}" (click)="ga.locationFlyoutClicked(currentStoreDetails,getFullAddress(currentStoreDetails),allStore?.length)">{{ currentStoreDetails!==undefined ? getFullAddress(currentStoreDetails) : 'Find Us on Google Maps' }}</a>
						</span>
						<div class="store-timimg-image" *ngIf="currentStoreDetails!==undefined">
							<img height="100" width="100" alt="Store 1"
								[defaultImage]="getImageUrl(currentStoreDetails.officeimage, true)"
								[lazyLoad]="getImageUrl(currentStoreDetails.officeimage, false)"
								alt="{{currentStoreDetails.officeimage ? currentStoreDetails.officeimage : 'Store List Image' }}">
						</div>
					</div>
					<div class="store-timimg-map" *ngIf="currentStoreDetails!==undefined && isMapClicked">
							<!-- <iframe title="gmap_canvas" id="gmap_canvas" src="" frameborder="0" scrolling="no" marginheight="0" loading="lazy" onclick="mapClicked($event)"
							marginwidth="0"></iframe> -->
							<a (click)="mapClicked()" class="my-image">
								<img id="my-image" alt="Image not found" [src]="getMapImageUrl(storeNumber)">
							</a>
					</div>
					<div class="clear10"></div>
					<button aria-label="button" mat-flat-button class="w-100" color="accent"
						(click)="LocationTimingBox = !LocationTimingBox ; locationBox()">Shop Another Store</button>
				</div>
			</div>
			<div class="store-bg" (click)="LocationTimingBox=false"></div>
		</div>
		<app-search class="app-search"></app-search>
		<div class="Buchheit-inner-header-btn-web">
			<!-- <button aria-label="button" mat-button color="primary" (click)="goToYourRebates();">
				<img height="46" width="100" alt="Brand 1"
					[defaultImage]="getHeaderImages('VisitourBuchheiteBateCenter.jpg', 'assets/images',  true, 100, 46)"
					[lazyLoad]="getHeaderImages('VisitourBuchheiteBateCenter.jpg', 'assets/images', false, 100, 46)"
					alt="Visitour Buchheit">
			</button> -->
			<ng-container *ngIf="!isloggedin">
				<button aria-label="button" mat-button color="primary" (click)="onLoginPopupClick()">
					<mat-icon aria-label="icon" aria-label="account" class="material-icons-outlined" [color]="'accent'">
						account_circle</mat-icon>
					Sign In/Register
				</button>
			</ng-container>
			<ng-container *ngIf="isloggedin">
				<button aria-label="button" mat-button color="primary" [matMenuTriggerFor]="menu" class="drop-btn">
					<mat-icon aria-label="icon" aria-label="account" class="material-icons-outlined" [color]="'accent'">
						account_circle</mat-icon>
					{{username}}
				</button>
			</ng-container>
			<button aria-label="button" mat-button color="primary" [routerLink]="['/cart']">
				<mat-icon aria-label="icon" class="material-icons-outlined" aria-label="cart" color="accent">shopping_cart
				</mat-icon>
				<span *ngIf="getCartCount===''">Cart</span>
				<span *ngIf="getCartCount!==''" matBadge={{getCartCount}} matBadgeOverlap="false" matBadgeColor="accent"
					matBadgeSize="small">Cart</span>
			</button>
		</div>
		<div class="Buchheit-inner-header-btn-tab">
			<!-- <button aria-label="button" mat-icon-button color="primary" (click)="goToYourRebates();">
				<img height="46" width="100" alt="Brand 1"
					[defaultImage]="getHeaderImages('VisitourBuchheiteBateCenter.jpg', 'assets/images',  true, 100, 46)"
					[lazyLoad]="getHeaderImages('VisitourBuchheiteBateCenter.jpg', 'assets/images', false, 100, 46)"
					alt="Visitour Buchheit">
			</button> -->
			<!-- <button aria-label="button" mat-icon-button class="d-md-none" (click)="locationBox()" *ngIf="!isLocationBarVisible()">
				<mat-icon aria-label="icon" aria-label="Place" class="material-icons-outlined">place</mat-icon>
			  </button> -->
			<button aria-label="button" mat-icon-button class="d-md-none" (click)="searchBar()">
				<mat-icon aria-label="icon" aria-label="Search" matPrefix>search</mat-icon>
			</button>
			<ng-container *ngIf="!isloggedin">
				<button aria-label="button" mat-icon-button class="d-none d-md-flex" (click)="onLoginPopupClick()">
					<mat-icon aria-label="icon" aria-label="account" class="material-icons-outlined " [style.color]="'grey'">account_circle
					</mat-icon>
				</button>
			</ng-container>
			<ng-container *ngIf="isloggedin">
				<button aria-label="button" mat-icon-button class="d-none d-md-flex" [matMenuTriggerFor]="menu">
					<mat-icon aria-label="icon" aria-label="account" class="material-icons-outlined ">account_circle
					</mat-icon>
				</button>
			</ng-container>
			<button aria-label="button" mat-icon-button>
				<mat-icon *ngIf="getCartCount===''" aria-label="icon" class="material-icons-outlined " aria-label="cart"
					[routerLink]="['/cart']">shopping_cart</mat-icon>
				<mat-icon aria-label="icon" class="material-icons-outlined " aria-label="cart" [routerLink]="['/cart']"
					*ngIf="getCartCount!==''" matBadge={{getCartCount}} matBadgeOverlap="false" matBadgeColor="accent"
					matBadgeSize="small">shopping_cart</mat-icon>
			</button>
			<button aria-label="button" mat-icon-button class="d-md-none" (click)="onHeadIconClick()">
				<mat-icon aria-label="icon"  class="material-icons-outlined " [style.color]="!isloggedin ? 'grey':null">account_circle</mat-icon>
			</button>
		</div>
	</div>
	<div class="Buchheit-location">
		<mat-icon aria-label="icon" class="material-icons-outlined" (click)="locationBox()">place</mat-icon>
		<div (click)="locationBox()">
			{{curentStoreName}}
			<br>
			Open until {{currentStoreOpenTill}}
		</div>
		<button aria-label="button" mat-button color="white" class="box-btn"
			(click)="isMapClicked = true; LocationTimingBox = !LocationTimingBox">
			<mat-icon aria-label="icon" class="material-icons-outlined">info</mat-icon>
		</button>
	</div>
</header>
<mat-menu #menu="matMenu">
	<button aria-label="button" mat-menu-item (click)="onNavigateto('yourprofile')">
		<mat-icon aria-label="icon" aria-label="account" class="material-icons-outlined" color="accent">account_circle
		</mat-icon>
		<span>Your Profile</span>
	</button>
	<mat-divider></mat-divider>
	<button aria-label="button" mat-menu-item (click)="onNavigateto('changepassword')">
		<mat-icon aria-label="icon" aria-label="lock" class="material-icons-outlined" color="accent">lock</mat-icon>
		<span>Change Password</span>
	</button>
	<mat-divider></mat-divider>
	<button aria-label="button" mat-menu-item (click)="onNavigateto('yourorder')">
		<mat-icon aria-label="icon" aria-label="local_mail" class="material-icons-outlined" color="accent">local_mall
		</mat-icon>
		<span>Your Orders</span>
	</button>
  <mat-divider></mat-divider>
    <button aria-label="button" mat-menu-item (click)="onNavigateto('orderhistory')">
	<mat-icon aria-label="icon" aria-label="local_mail" class="material-icons-outlined" color="accent">history
	</mat-icon>
	<span>Order History</span>
    </button>
  <mat-divider></mat-divider>
	<button aria-label="button" mat-menu-item (click)="onNavigateto('yourwishlist')">
		<mat-icon aria-label="icon" aria-label="favorite" class="material-icons-outlined" color="accent">favorite_border
		</mat-icon>
		<span>Your Wishlist</span>
	</button>
	<mat-divider></mat-divider>
	<button aria-label="button" mat-menu-item (click)="onNavigateto('youraddress')">
		<mat-icon aria-label="icon" aria-label="place" class="material-icons-outlined" color="accent">place</mat-icon>
		<span>Your Address</span>
	</button>
	<mat-divider></mat-divider>
	<button aria-label="button" mat-menu-item (click)="onNavigateto('yourrebates')">
		<mat-icon class="material-icons-outlined" color="accent">monetization_on</mat-icon>
		<span>Your ebates</span>
	</button>
	<mat-divider></mat-divider>
	<button aria-label="button" mat-menu-item (click)="onNavigateto('yourgiftcards')">
		<mat-icon class="material-icons-outlined" color="accent">card_giftcard</mat-icon>
		<span>Your Gift Cards</span>
	</button>
	<mat-divider></mat-divider>
	<button aria-label="button" mat-menu-item (click)="onLogout()">
		<mat-icon aria-label="icon" aria-label="logout" class="material-icons-outlined" color="accent">logout</mat-icon>
		<span>Sign Out</span>
	</button>
</mat-menu>

<header class="Buchheit-inner-header" *ngIf="isCheckoutPage() && !isGuestCheckoutPage()">
	<button aria-label="button" mat-icon-button color="primary" class="d-md-none" (click)="commonService.goBack()"
		*ngIf="pageRoot!=='order'">
		<mat-icon aria-label="icon" aria-label="back">arrow_back_ios_new</mat-icon>
	</button>
	<h1 class="Buchheit-inner-header-logo d-none d-md-block " [routerLink]="['/']">
		<img [defaultImage]="getHeaderImages('logo.png', 'assets/images',  true, 198, 80)"
			[lazyLoad]="getHeaderImages('logo.png', 'assets/images', false, 198,80)" alt="images" width="100"
			height="100">
	</h1>
	<!-- (click)="backClicked()" -->
	<div class="Buchheit-inner-header-checkout">
		<ul class="checkout-process">
			<li [ngClass]="pageRoot=='review'?'active':pageRoot=='billing'?'done':pageRoot=='order'?'done':''"
				(click)="goOrderReviewPage()">
				Order Review</li>
			<li [ngClass]="pageRoot=='billing'?'active':pageRoot=='order'?'done':''" (click)="goBillingPaymentPage()">
				Billing & Payment</li>
			<li [ngClass]="pageRoot=='order'?'active':''">Order Complete</li>
		</ul>
	</div>
	<button mat-icon-button [routerLink]="['/']" class="d-md-none ml-auto">
		<mat-icon class="material-icons-outlined">home</mat-icon>
	</button>
</header>

<header class="Buchheit-inner-header" *ngIf="isGiftCardPage() ">
	<button aria-label="button" mat-icon-button color="primary" class="d-md-none" (click)="commonService.goBack()"
		*ngIf="pageRoot!=='giftcard'">
		<mat-icon aria-label="icon" aria-label="back">arrow_back_ios_new</mat-icon>
	</button>
	<h1 class="Buchheit-inner-header-logo d-none d-md-block " [routerLink]="['/']">
		<img [defaultImage]="getHeaderImages('logo.png', 'assets/images',  true, 198, 80)"
			[lazyLoad]="getHeaderImages('logo.png', 'assets/images', false, 198,80)" alt="images" width="100"
			height="100">
	</h1>
	<!-- (click)="backClicked()" -->
	<div class="Buchheit-inner-header-checkout">
		<ul class="checkout-process">
			<li [ngClass]="pageRoot=='giftcard-shipping'?'active':pageRoot=='billing'?'done':pageRoot=='order'?'done':''"
				(click)="goGiftcardReviewPage()">
				Shipping</li>
			<li [ngClass]="pageRoot=='giftcard-billing'?'active':pageRoot=='order'?'done':''"
				(click)="goBillingPaymentPage()">Billing & Payment</li>
			<li [ngClass]="pageRoot=='order'?'active':''">Order Complete</li>
		</ul>
	</div>
	<button mat-icon-button [routerLink]="['/']" class="d-md-none ml-auto">
		<mat-icon class="material-icons-outlined">home</mat-icon>
	</button>
</header>
<header class="Buchheit-inner-header" *ngIf="isGuestCheckoutPage()">
    <h1 class="Buchheit-inner-header-logo d-none d-md-block " [routerLink]="['/']" *ngIf="!trackOrderPage()">
        <img [defaultImage]="getHeaderImages('logo.png', 'assets/images',  true, 198, 80)"
            [lazyLoad]="getHeaderImages('logo.png', 'assets/images', false, 198,80)" alt="images" width="100"
            height="100">
    </h1>
    <div class="Buchheit-inner-header-checkout d-none d-md-block" *ngIf="!trackOrderPage()">
        <ul class="checkout-process">
            <li [ngClass]="returnClassElement('1')" (click)="gotoGuestOrderOverview()">
                Order Review</li>
            <li [ngClass]="returnClassElement('2')" (click)="gotoGuestOrderOverview()">
                Billing & Payment</li>
            <li [ngClass]="returnClassElement('3')">Order Complete</li>
        </ul>
    </div>
</header>


<!-- <div class="Buchheit-location" *ngIf="isLocationBarVisible() ">
	<mat-icon aria-label="icon" class="material-icons-outlined" (click)="locationBox()">place</mat-icon>
	<div (click)="locationBox()">
		{{curentStoreName}}
		<br>
		Open until {{currentStoreOpenTill}}
	</div>
	<button aria-label="button" mat-button color="white" class="box-btn"
		(click)="isMapClicked = true; LocationTimingBox = !LocationTimingBox">
		<mat-icon aria-label="icon" class="material-icons-outlined">info</mat-icon>
	</button>
</div> -->
<div class="store-box" id="Location-Box">
	<div class="store-heading">
		<button aria-label="button" mat-icon-button (click)="locationBox()" class="d-md-none">
			<mat-icon aria-label="icon" aria-label="left">chevron_left</mat-icon>
		</button>
		<div>Find your Buchheit Store</div>
		<button aria-label="button" mat-icon-button (click)="locationBox()" class="ml-auto d-none d-md-block">
			<mat-icon aria-label="icon" aria-label="close">close</mat-icon>
		</button>
	</div>
	<div class="store-search">
		<mat-form-field appearance="outline" class="no-label">
			<mat-icon aria-label="icon" aria-label="search" matPrefix>search</mat-icon>
			<input matInput placeholder="Zip Code, City, or State..." [ngModel]="search" (input)="searchStore($event);">
		</mat-form-field>
	</div>
	<ul class="store-list">
		<ng-container *ngIf="allStore?.length>0">
			<li *ngFor="let item of allStore" (click)="changeStoreOnBookingCart(item);"
				[ngClass]="{ 'active' : +item.id === +curentStoreId }">
				<div class="store-list-active">
					<mat-icon aria-label="icon" aria-label="circle" class="material-icons-outlined">check_circle
					</mat-icon> You’re Shopping Here
				</div>
				<div class="store-list-name">{{item.storename}}
					<span *ngIf="item.locationOn">{{item.nearestDistance | number : '1.2-2'}} mi</span>
				</div>
				<div class="store-list-details">
					<span class="text-green" *ngIf="getClosingTime(item.officetimings)">Open until
						{{getClosingTime(item.officetimings)}}</span>
					<span><a href="tel:{{item.phonenumber || '618-532-2189'}}">{{item.phonenumber || '618-532-2189'}}</a></span>
					<span><a  href="http://maps.google.com/?q={{getFullAddress(item)}}" (click)="ga.locationFlyoutClicked(item,getFullAddress(item),allStore?.length)">{{getFullAddress(item) || 'Location Flyout'}}</a></span>
					<button aria-label="button" mat-stroked-button color="accent" class="sm-btn"
						*ngIf="+item.id !== +curentStoreId">Shop This Store</button>
				</div>
				<div class="store-list-image">
					<img height="100" width="100" alt="Store 1" [defaultImage]="getImageUrl(item.officeimage, true)"
						[lazyLoad]="getImageUrl(item.officeimage, false)"
						alt="{{item.officeimage ? item.officeimage : 'Store List Image' }}">
				</div>
			</li>
		</ng-container>
		<ng-container *ngIf="allStore?.length===0">
			<li>No Store Found</li>
		</ng-container>
	</ul>
</div>
<div class="store-box available" id="Store-Box">
	<div class="store-heading">
		<button aria-label="button" mat-icon-button (click)="storeBox()" class="d-md-none">
			<mat-icon aria-label="icon" aria-label="left">chevron_left</mat-icon>
		</button>
		Other Location Availability
		<button aria-label="button" mat-icon-button (click)="storeBox()" class="ml-auto d-none d-md-block">
			<mat-icon aria-label="icon" aria-label="close">close</mat-icon>
		</button>
	</div>
	<div class="store-search">
		<mat-form-field appearance="outline" class="no-label">
			<mat-icon aria-label="icon" aria-label="search" matPrefix>search</mat-icon>
			<input matInput placeholder="Zip Code, City, or State..." [ngModel]="search" (input)="searchStore($event);">
		</mat-form-field>
	</div>
	<ul class="store-list">
		<ng-container *ngIf="allStore?.length>0">
			<li *ngFor="let item of allStore" [ngClass]="{ 'active' : +item.id === +curentStoreId }">
				<div class="store-list-active">
					<mat-icon aria-label="icon" aria-label="circle" class="material-icons-outlined">check_circle
					</mat-icon> You’re Shopping Here
				</div>
				<div class="store-list-name">{{item.storename}}
					<span *ngIf="item.locationOn">{{item.nearestDistance | number : '1.2-2'}} mi</span>
				</div>
				<div class="store-list-details">
					<span class="text-green" *ngIf="getClosingTime(item.officetimings)">Open until
						{{getClosingTime(item.officetimings)}}</span>
					<span>{{item.phonenumber}}</span>
					<span>{{getFullAddress(item)}}</span>
					<div class="row align-items-center">
						<div class="col">
							<button aria-label="button" mat-stroked-button color="accent" class="sm-btn"
								(click)="changeStoreOnBookingCart(item);"
								*ngIf="+item.id !== +curentStoreId && item.stock > 0">Shop This Store</button>
						</div>
						<div class="col-auto font-bold"
							[ngClass]="{'text-danger': (!item?.stock), 'text-success':item?.stock > 0}">
							{{item?.stock || 0}} Available
						</div>
					</div>
				</div>
			</li>
		</ng-container>
		<ng-container *ngIf="allStore?.length===0">
			<li>No Store Found</li>
		</ng-container>
	</ul>
</div>
<div class="store-bg" (click)="locationBox()"></div>


<div class="store-box available"  id="Cart-Box">
	<div class="store-heading">
		<button aria-label="button" mat-icon-button (click)="cartBox()" class="d-md-none">
			<mat-icon aria-label="icon" aria-label="left">chevron_left</mat-icon>
		</button>
		Add to Cart {{getCount ? "( "+getCount+" )" : ""}}
		<button aria-label="button" mat-icon-button (click)="cartBox()" class="ml-auto d-none d-md-block">
			<mat-icon aria-label="icon" aria-label="close">close</mat-icon>
		</button>
	</div>
	
	  <app-cart-add></app-cart-add>
	  
	  
</div>
<div class="store-bg" (click)="closeBox()"></div>

<!-- <div class="store-location-bg" (click)="storeBox()"></div> -->
